<template>
  <div class="card text-center" >
    <router-link :to="{ path: this.dynamicContent.metadata.link }">
      <ResponsiveImage :image="this.dynamicContent.metadata.image.imgix_url" alt="today schedule" hl="300" wl="550"></ResponsiveImage>
    </router-link>

    <div
      class="text-left"
      style="margin-top: 10px; padding: 1px"
      v-html="this.dynamicContent.content"
    ></div>
  </div>
</template>
<script>
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';
export default {
  name: 'ClubActivity',
  props: 'color',
  components: { ResponsiveImage },
  created() {
    this.$store.dispatch('getDynamicContent');
  },
  computed: {
    dynamicContent: {
      get() {
        return this.$store.state.dynamicContent;
      },
    },
  },
};
</script>

