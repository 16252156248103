<template>
  <div class="content">
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1d1d1d, black)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >
      <section class="text-center col-lg-8 col-sm-12">
        <vue-whatsapp-widget
          phoneNumber="+524421867547"
          companyName="tulum.party (Gaby)"
          textReply="Typically replies within 10-15 mins (9am - 10pm)"
          companyLogo="https://imgix.cosmicjs.com/4674bea0-3e73-11ec-9828-0f2f00f103e0-telegram-peer-photo-size-1-5166023334515814245-1-0-0.jpg?h=50"
        />

        <div class="header text-center" style="margin-bottom: -35px">
          <HomeMainImage />
          <br />
        </div>
        <div class="row">
          <div class="col-md-12">
            <card style="z-index: 1; text-align: center; padding: 10px">
              <template slot="header">
                <p class="text-2xl">
                  <strong>Tulum, Mexico </strong>is a world-wide recognized spot
                  for its electronic music <a href="#event-line"> parties</a>,
                  <router-link :to="{ path: '/tulum-clubs' }">
                    nightclubs</router-link
                  >
                  and festive
                  <router-link :to="{ path: '/tulum-nightlife' }"
                    >nightlife</router-link
                  >
                  . Surrounded by impressive landscapes,
                  <strong>Tulum</strong> congregates the
                  <strong>best world DJ's along open minded travelers</strong>
                  from all around the globe.
                </p>
              </template>
              <template slot="default">
                <div style="text-align: center !important">
                  <br />
                  <small>PLAN A | <strong>SIDE B</strong></small>
                  <br />
                  <br />
                  <div
                    class="card"
                    style="
                      z-index: 1;
                      width: 300px;
                      margin-left: auto;
                      margin-right: auto;
                      left: 0;
                      right: 0;
                    "
                  >
                    <p style="font-weight: bold">
                      “Whenever you find yourself on the side of the majority,
                      it is time to pause and reflect.” -Mark Twain.
                    </p>
                  </div>
                  <a href="https://www.instagram.com/tulum.party/">
                    &nbsp;
                    <img v-lazy="insta" height="30px" width="30px" alt="follow tulum party in instagram"/> Follow Us
                  </a>
                  <br />
                  <br />

                  <p>
                    <strong>tulum.party</strong><br />
                    Riviera Maya's leading party promoter for six years strong.
                    Over 10,000 events and counting. We've been there through
                    thick and thin, keeping the party alive.
                  </p>

                  <br />
                  <br />
                  <div>
                    <router-link :to="{ path: '/tulum-clubs' }"
                      >DJ Schedule</router-link
                    >
                    |
                    <router-link :to="{ path: '/tulum-clubs' }"
                      >Clubs</router-link
                    >
                    |
                    <router-link :to="{ path: '/tulum-beach-clubs' }"
                      >Beach Clubs</router-link
                    >
                    |
                    <router-link :to="{ path: '/calendar' }"
                      >Calendar
                    </router-link>
                    |
                  </div>

                  <hr />
                </div>
              </template>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <card
              class="card"
              style="
                z-index: 1;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                margin-top: -80px;
                background-color: #151414;
                padding: 15px;
              "
            >
              <template slot="header">
                <br />
                <ClubActivity></ClubActivity>
              </template>
              <slot>
                <div class="text-center">
                  <a
                    href="https://wa.me/524421867547?text=Thanks%20for%20contacting%20us!%20How%20can%20we%20help%3F"
                    v-on:click="onQuickReserveClick('Party Support')"
                    class="btn btn-sm btn-primary font-weight-bold d-inline-xs"
                    style="
                      width: 80%;

                      margin-left: auto;
                      margin-right: auto;
                      left: 0;
                      right: 0;
                      padding: 10px;
                      border: 1px solid silver;
                    "
                  >
                    📲 INFO & RESERVATIONS
                  </a>

                  <br />
                  <br />

                  <br />
                  <a href="mailto:bookings@tulum.party">bookings@tulum.party</a>

                  <br />

                  <small>
                    We operate from 9AM - 10PM / Answer within 15 mins</small
                  >
                  <br />
                  <br />
                  <small style="color: #fff; font-weight: bolder"
                    >NO EXTRA FEE</small
                  >
                </div>
              </slot>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <card
              class="card text-center"
              style="
                z-index: 1;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                margin-top: -80px;
                max-width: 550px;
                background-color: #151414;
                padding: 15px;
              "
            >
              <template slot="header">
                <router-link :to="{ name: 'Clubs' }">
                  <ResponsiveImage
                    :image="clubsSpirt"
                    alt="tulum-clubs"
                  ></ResponsiveImage>

                  <h4
                    class="tulum-party-title"
                    style="
                      margin-bottom: -5px;
                      margin-top: 20px;
                      font-size: 24px;
                    "
                  >
                    TOP CLUBS
                  </h4>

                  <small
                    style="margin-top: -35px"
                    class="tulum-party-subtitle-bg;"
                  >
                    We take you into the Tulum's Elite Club Scene
                  </small>

                  <br />
                  <br />
                  <h5>
                    <strong>Electronic Music</strong> is all over the place.
                    <br />High end restaurants,
                    <router-link :to="{ path: '/tulum-day-beach-clubs' }">
                      Beach clubs
                    </router-link>
                    &
                    <router-link :to="{ path: '/tulum-clubs' }"
                      >Night clubs</router-link
                    >
                  </h5>
                </router-link>
                <br />
              </template>
            </card>
          </div>
        </div>

        <HotThisWeek align="text-center" :secondary-color="secondaryColor" />

        <div
          class="text-center"
          :style="{
            background: `linear-gradient( 45deg, ${this.primaryColor}, ${this.secondaryColor})`,
          }"
        >
          <h3 class="section-header text-center" style="font-size: 1.8rem">
            UPCOMING EVENTS
          </h3>

          <div class="row">
            <div class="col-sm-12 col-lg-10 offset-lg-1">
              <div
                class="text-center"
                style="margin-top: 1px"

              >
                <div class="form-group form-control">
                  <p style="margin-bottom: -25px">Searching for something?</p>
                  <div
                    class="input-group input-group-lg"
                    style="
                      max-width: 300px;
                      margin-left: auto;
                      margin-right: auto;
                      left: 0;
                      right: 0;
                      padding: 30px;
                      margin-top: 2px;
                    "
                  >
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="sizing-addon1"
                      name="search_nye"
                      autocomplete="false"
                      v-model.lazy="searchTerm"
                      v-on:change="filterParties"
                      placeholder="Search ..."
                    />
                  </div>
                </div>
                <p class="text-center section-header">
                  &nbsp; Events from: {{ incomingParties[0].metadata.date }} to
                  {{
                    incomingParties[incomingParties.length - 1].metadata.date
                  }}
                </p>
                <h4 v-if="searchTerm && this.incomingParties.length === 0">
                  No match for your query: {{ searchTerm }}
                </h4>
                <p
                  v-if="searchTerm"
                  style="font-size: 14px; margin-top: 10px; cursor: pointer"
                  class="badge badge-dark"
                  @click="
                    searchTerm = null;
                    filterParties();
                  "
                >
                  <span>{{ incomingParties.length }} events found</span>
                  <br /><br />

                  <span style="color: #4ba663">x</span> Filter:
                  {{ searchTerm }}
                </p>
                <h4 v-if="view === 'artist'">DJ Schedule</h4>

                <time-line v-if="view === 'detailed'">
                  <time-line-item
                    :full="true"
                    v-for="(event, idx) in incomingParties"
                    :key="event._id"
                    :inverted="idx % 2 === 0"
                  >
                    <div slot="content">
                      <pre style="background-color: #0e0e0e; opacity: 0.3"
                        >{{ idx + 1 }} mts</pre
                      >

                      <EventCard
                        :with-lineup="false"
                        v-bind:event="event"
                        :key="event._id"
                        :idx="idx"
                      ></EventCard>
                    </div>
                  </time-line-item>
                </time-line>
                <div v-if="view === 'compact'">
                  <mini-event-card
                    :event="event"
                    :key="event.slug"
                    v-for="event in this.festivalParties"
                  ></mini-event-card>
                </div>

                <div class="text-center centered">
                  <router-link
                    class="btn btn-call-for-action"
                    :to="{
                      path:
                        '/tulum-party-mexico-event-calendar?period=this_month',
                    }"
                  >
                    <img v-lazy="mainImage" alt="full event calendar"/>
                    <br>
                    <p>Full Event Calendar</p>
                  </router-link>
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <div class="text-center">
          <SmartMenu
            :links="links"
            :logo="this.astroImg"
            :primary-color="primaryColor"
          >
            <p>
              All <router-link :to="{path: 'Clubs'}">Clubs</router-link> & Events <br />Information & Reservations <br /><br />
            </p>
          </SmartMenu>

          <br />
          <br />
        </div>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <div class="row">
          <SmartMenu
            :links="links"
            :logo="astroImg"
            top-section-name="TALK TO US"
            cta-text="DROP US A LINE"
            :primary-color="primaryColor"
          >
            <p>
              All Clubs & Events <br />Information & Reservations <br /><br />
            </p>
          </SmartMenu>
          <section
            style="margin-top: 35px"
            class="card row"
            :style="{
              background: `linear-gradient(${this.secondaryColor}, black)`,
            }"
          >
            <h3 class="text-left section-header">&nbsp;DJ SCHEDULE</h3>
            <hr
              style="height: 1px; margin-top: -10px"
              :style="{ backgroundColor: this.secondaryColor }"
            />

            <small style="padding: 20px">
              <router-link :to="{ path: '/tulum-dj-schedule' }"
                >Check The Full Dj Schedule Here</router-link
              >
            </small>

            <DjScheduleCollection
              :nye="true"
              :desktop="true"
            ></DjScheduleCollection>
          </section>

          <section
            style="margin-top: 35px"
            class="card row"
            :style="{
              background: `linear-gradient(${this.secondaryColor}, black)`,
            }"
          >
            <h3 class="section-header">Production Brands</h3>
            <BrandCollection></BrandCollection>
          </section>
        </div>
      </aside>
    </article>
  </div>
</template>
<script>
import EventCard from '../../components/EventCard/EventCard';
import TimeLine from '@/components/Timeline/TimeLine';
import TimeLineItem from '@/components/Timeline/TimeLineItem';
import ClubActivity from '@/components/ClubActivity/ClubActivity';
import HomeMainImage from '@/pages/Home/HomeMainImage';
import BrandCollection from '../../components/Cards/BrandCollection';
import SmartMenu from '../../components/SmartMenu/SmartMenu';
import DjScheduleCollection from '../../components/Cards/DjScheduleCollection';
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';
import HotThisWeek from '@/pages/TulumDjSchedule/HotThisWeek';

const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
  );
// const DynamicCard = () => import('@/components/Cards/DynamicCard');

export default {
  name: 'Home',
  created: function () {
    this.$store.dispatch('getParties');
    this.$store.dispatch('getLinks', { slug: 'tulum-event-line' });
  },
  metaInfo() {
    return {
      title:
        'TULUM PARTY · Parties, Nightlife & Techno Music · Tulum Top Events & Clubs',
      // link: [{rel: 'preload', as: 'image',
      //   href: this.mainImage}],
      meta: [
        {
          name: 'description',
          content:
            'TULUM.PARTY - We put together the best events happening around Tulum. Tickets and Reservations for the best parties & clubs. Join the largest party community',
        },
        {
          name: 'keywords',
          content:
            'Tulum, Parties, Party, DJ, Techno, nightlife, clubs, Festival, Vagalume, Mia, Tonight, Zamna, 2021, Papaya Playa, Fullmoon, Events, Tickets, Bagatelle, Playa del Carmen',
        },
        {
          property: 'og:site_name',
          content: `TULUM PARTY`,
        },
        {
          property: 'og:title',
          content: `TULUM PARTY: Parties, Nightlife & Techno Music. Top Events & Clubs In Tulum`,
        },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: `TULUM.PARTY - We put together the best events happening around Tulum. Tickets and Reservations for the best parties & clubs. Recommendations and reviews.`,
        },
        { property: 'og:locality', content: 'Tulum' },
        { property: 'og:country-name', content: 'Mexico' },
        {
          property: 'og:image',
          content: this.mainImage,
        },
      ],
    };
  },
  data: () => {
    return {
      modal: {},
      showPalms: false,
      showingAll: false,
      view: 'detailed',
      events: [],
      showSearch: true,
      searchTerm: null,
      primaryColor: '#1C1C1F',
      secondaryColor: '#003e3e',
      thirdColor: '#007c7c',
      fourthColor: '#00baba',

      //#000000
      //#003e3e
      //#007c7c
      //#00baba
      //#00f8f8

      eventLinePrimaryColor: '#003e3e',
      eventLineSecondaryColor: '#262525',
      palmeras:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      tulumpartyLogo:
        'https://imgix.cosmicjs.com/afd1db60-3abd-11ee-b850-2d3046e40f87-poster.png',
      insta:
        'https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png',
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&duotone=aquamarine,FA8072&duotone-alpha=100&auto=format',
      clubsSpirt:
        'https://imgix.cosmicjs.com/a9432ac0-b923-11ef-bee4-3bb1d3c55332-papaya.jpg',
      birdsImg:
        'https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png',
      calendarImg:
        'https://imgix.cosmicjs.com/91cad540-ac13-11ef-bee4-3bb1d3c55332-diver-rayado.jpg',
      calendarImg2:
        'https://imgix.cosmicjs.com/f641f1a0-5bc0-11ef-bbaa-af49ecc8228f-Captura-de-pantalla-2024-08-16-051613.png',
      logito:
        'https://imgix.cosmicjs.com/846479a0-9cf2-11ee-93ed-8bfbf1aced8f-image.png',
      instagram:
        'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      mainImage:
        'https://imgix.cosmicjs.com/3c6c5b60-96ea-11eb-b593-972a7dbc1054-papaya-playa-small.png?h=200&w=400&auto=format&fit=fillmax&fill=blur',
      marcianitos:
        'https://imgix.cosmicjs.com/dbcd2d20-afde-11ec-97bc-19d12908cbbe-image.png',
    };
  },
  components: {
    ResponsiveImage,
    HotThisWeek,
    HomeMainImage,
    EventCard,
    TimeLine,
    TimeLineItem,
    ClubActivity,
    BrandCollection,
    SmartMenu,
    DjScheduleCollection,
    VueWhatsappWidget,
  },
  methods: {
    onQuickReserveClick() {
      window.ga('send', 'event', 'reservations', 'PartySupport', 'Main', 20);
      this.$rollbar.info('PartySupport');
    },
    filterParties: function filterParties() {
      if (this.searchTerm) {
        if (this.searchTerm.length > 2) {
          this.$rollbar.info(' SEARCH', { keyword: this.searchTerm });
        }
        // this.notifyChange('Showing Results For: ' + this.searchTerm);
        // this.changeView('compact');
        return [...this.$store.state.incomingParties].filter((item) => {
          return this.searchTerm
            ? item.slug.match(this.searchTerm.toLowerCase()) ||
                item.title.toLowerCase().match(this.searchTerm.toLowerCase()) ||
                item.metadata.lineup
                  .toLowerCase()
                  .match(this.searchTerm.toLowerCase())
            : true;
        });
      }
    },

    showMore(id) {
      this.modal = {};
      this.modal[id] = true;
    },
    showLess(id) {
      this.modal = {};
      this.modal[id] = false;
    },
    goToParty(event) {
      this.$rollbar.info('QUICK PARTY: ' + event.target.value);
      if (event.target.value === 'calendar') {
        this.$router.push({
          path: '/tulum-party-mexico-event-calendar',
          replace: true,
        });
        return;
      }
      if (event.target.value === 'zamna') {
        this.$router.push({ path: '/brands/zamna-tulum', replace: true });
        return;
      }
      this.$router.push({
        path: '/event/' + event.target.value,
        replace: true,
      });
    },
    formatDate(event) {
      let date = new Date(event.metadata.date).toISOString();
      return `${date.split('T')[0].split('-')[1]}/${
        date.split('T')[0].split('-')[2]
      } >> ${event.title.split('@')[0]}`;
    },
    badgeType(idx) {
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    },
  },
  updated() {
    setTimeout(() => (this.showPalms = true), 5000);
  },
  computed: {
    partiesTotal: {
      get() {
        return this.$store.state.parties_total;
      },
    },
    article: {
      get() {
        return this.$store.state.article;
      },
    },
    incomingParties: {
      get() {
        return this.filterParties() || this.$store.state.incomingParties;
      },
    },
    links: {
      get() {
        return this.$store.state.links || [];
      },
    },
  },
};
</script>
